import React from 'react'
import { Link } from 'react-router-dom'

const ServiceFour = () => {
    return (
        <section className='bg-dark'>
            <div className="service-area-3 space-top space-bottom overflow-hidden">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-6">
                            <div className="title-area text-center">
                                <span className="sub-title text-white">
                                    <img src="/assets/img/icon/title_left.svg" alt="shape" />
                                    Our Services
                                </span>
                                <h2 className="sec-title style2 text-white">
                                Driving Success from Technology{" "}
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row gx-30 gy-30 justify-content-center">
                        <div className="col-xl-4 col-md-6">
                            <div className="service-card-3">
                                <div className="service-card-icon">
                                    <img src="/assets/img/icon/gd.png" alt="img" />
                                </div>
                                <div className="service-card_content">
                                    <h4 className="service-card_title">
                                        <Link to="/services/graphic-designing">Graphic Design</Link>
                                    </h4>
                                    <p className="service-card_text">
                                        Digious Solutions is a leading graphic agency that offers the highest high-quality graphic design services. Our talented graphic designers can create
                                    </p>
                                    <Link to="/services/graphic-designing" className="link-btn">
                                        Learn More <i className="fas fa-arrow-right" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="service-card-3">
                                <div className="service-card-icon">
                                    <img src="/assets/img/icon/dev_icon.png" alt="img" />
                                </div>
                                <div className="service-card_content">
                                    <h4 className="service-card_title">
                                        <Link to="/services/website-development">Website Development</Link>
                                    </h4>
                                    <p className="service-card_text">
                                        Digious Solutions serves as a Website Development Agency believing in the vision of creativity. It fills as your premier Web Developer and Web Designer. 
                                    </p>
                                    <Link to="/services/website-development" className="link-btn">
                                        Learn More <i className="fas fa-arrow-right" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="service-card-3">
                                <div className="service-card-icon">
                                    <img src="/assets/img/icon/ecom_icon.png" alt="img" />
                                </div>
                                <div className="service-card_content">
                                    <h4 className="service-card_title">
                                        <Link to="/services/ecommerce-solutions">E-Commerce Solution</Link>
                                    </h4>
                                    <p className="service-card_text">
                                        We can convert your ideas to life by delivering our digital experiences. With us, build and grow your e-commerce online business effectively
                                    </p>
                                    <Link to="/services/ecommerce-solutions" className="link-btn">
                                        Learn More <i className="fas fa-arrow-right" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="service-card-3">
                                <div className="service-card-icon">
                                    <img src="/assets/img/icon/game_icon.png" alt="img" />
                                </div>
                                <div className="service-card_content">
                                    <h4 className="service-card_title">
                                        <Link to="/services/game-development">Game Development</Link>
                                    </h4>
                                    <p className="service-card_text">
                                    Digious Solution is a competent mobile app development firm that provides you a wide-ranging store for fulfilling all of your android
                                    </p>
                                    <Link to="/services/game-development" className="link-btn">
                                        Learn More <i className="fas fa-arrow-right" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="service-card-3">
                                <div className="service-card-icon">
                                    <img src="/assets/img/icon/eng_icon.png" alt="img" />
                                </div>
                                <div className="service-card_content">
                                    <h4 className="service-card_title">
                                        <Link to="/services/mobile-app-development">Mobile App Development </Link>
                                    </h4>
                                    <p className="service-card_text">
                                        Digious Solution is the right and most convenient platform that bestows you your best mobile app developers.
                                    </p>
                                    <Link to="/services/mobile-app-development" className="link-btn">
                                        Learn More <i className="fas fa-arrow-right" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="service-card-3">
                                <div className="service-card-icon">
                                    <img src="/assets/img/icon/database.png" alt="img" />
                                </div>
                                <div className="service-card_content">
                                    <h4 className="service-card_title">
                                        <Link to="/services/software-development">Software Development </Link>
                                    </h4>
                                    <p className="service-card_text">
                                        We understand that every business has specific requirements. Our team of skilled software developers works closely with you to conceptualize,
                                    </p>
                                    <Link to="/services/software-development" className="link-btn">
                                        Learn More <i className="fas fa-arrow-right" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="service-card-3">
                                <div className="service-card-icon">
                                    <img src="/assets/img/icon/megaphone.png" alt="img" />
                                </div>
                                <div className="service-card_content">
                                    <h4 className="service-card_title">
                                        <Link to="/services/digital-marketing">Digital Marketing </Link>
                                    </h4>
                                    <p className="service-card_text">
                                        We set out years ago to create a contemporary shop for new-age businesses seeking to pitch their products in the competitive digital world.
                                    </p>
                                    <Link to="/services/digital-marketing" className="link-btn">
                                        Learn More <i className="fas fa-arrow-right" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-xl-4 col-md-6">
                            <div className="service-card-3">
                                <div className="service-card-icon">
                                    <img src="/assets/img/icon/token.png" alt="img" />
                                </div>
                                <div className="service-card_content">
                                    <h4 className="service-card_title">
                                        <Link to="/service-details">Blockchain Development </Link>
                                    </h4>
                                    <p className="service-card_text">
                                        Step into the future of digital ownership with Digious Solutions, your gateway to the Non-Fungible Token (NFT) revolution. Immerse yourself in a world where art meets.
                                    </p>
                                    <Link to="/service-details" className="link-btn">
                                        Learn More <i className="fas fa-arrow-right" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="service-card-3">
                                <div className="service-card-icon">
                                    <img src="/assets/img/icon/sqa.png" alt="img" />
                                </div>
                                <div className="service-card_content">
                                    <h4 className="service-card_title">
                                        <Link to="/service-details">Software Quality Assurance </Link>
                                    </h4>
                                    <p className="service-card_text">
                                        Digious Solutions stands out as your partner in excellence, seamlessly integrating quality assurance (QA) into every part of the software development journey.
                                    </p>
                                    <Link to="/service-details" className="link-btn">
                                        Learn More <i className="fas fa-arrow-right" />
                                    </Link>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceFour
